import { Dispatch, useContext } from "react";
import MenuCard from "./MenuCard";
import {
  ActionType,
  MetadataContext,
  MetadataStateModel,
} from "@/context/metadata.context";
import { Button } from "primereact/button";
import { useRouter } from "next/navigation";

export default function PopularMenu({ helperCls }: { helperCls: string }) {
  const router = useRouter();
  const { state } = useContext<{
    state: MetadataStateModel;
    dispatch: Dispatch<ActionType>;
  }>(MetadataContext);

  return (
    <>
      <section
        id="menu-6"
        className={`wide-70 menu-section division ${
          helperCls ? helperCls : ""
        }`}
      >
        <div className="container">
          {/* SECTION TITLE */}
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="section-title mb-60 text-center">
                {/* Title 	*/}
                <h2 className="h2-xl red-color">Popular Menu</h2>
                <p>Popular Picks for you, based on trends</p>
              </div>
            </div>
          </div>
          <div className="row">
            {state?.items
              ?.filter(
                (res) =>
                  res.settings?.oosPopularMenuEnabled &&
                  !res.tags.some((tag) =>
                    state.currentSlot?.excludedTags.includes(tag)
                  )
              )
              .map((ele, index) => {
                return (
                  <MenuCard
                    item={ele}
                    location={state.location}
                    categories={state.categories}
                    key={index}
                  ></MenuCard>
                );
              })}
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-salmon-background"
              style={{ height: 48, width: "180px" }}
              label="See more..."
              onClick={() => router.push("/order-online")}
            />
          </div>
        </div>{" "}
        {/* End container */}
      </section>
    </>
  );
}
