"use client";

import {
  ActionType,
  MetadataContext,
  MetadataStateModel,
} from "@/context/metadata.context";
import Layout from "@/components/layout/Layout";
import PopularMenu from "@/components/sections/menu/PopularMenu";
import { Dispatch, useContext } from "react";
import dynamic from "next/dynamic";
const Carousel = dynamic(() =>
  import("primereact/carousel").then((mod) => mod.Carousel)
);
const Hero1 = dynamic(() => import("@/components/sections/hero/Hero1"));

export default function HomeClient() {
  const { state } = useContext<{
    state: MetadataStateModel;
    dispatch: Dispatch<ActionType>;
  }>(MetadataContext);

  const productTemplate = (message) => {
    return (
      <div className="text-center p-2">
        <p className="mb-0 carousel-text">{message}</p>
      </div>
    );
  };

  return (
    <>
      <Layout
        headerStyle={1}
        headerCls="viewport-lg"
        headTitle={`Home - ${state.location?.name ?? ""}`}
      >
        {state.location?.oosConfig?.home.messages?.length > 0 ? (
          <Carousel
            value={state.location?.oosConfig?.home.messages}
            numVisible={1}
            numScroll={1}
            itemTemplate={productTemplate}
            showIndicators={false}
            showNavigators={state.location?.oosConfig?.home.messages.length > 1}
            autoplayInterval={4000}
            circular={state.location?.oosConfig?.home.messages.length > 1}
          />
        ) : (
          <></>
        )}

        <Hero1 helperCls="" />
        <PopularMenu helperCls="" />
      </Layout>
    </>
  );
}
